import React from "react";
import Star from "@/Components/Icons/Star.jsx";

export default function Rating() {
	return (
		<div className="d-flex justify-content-center mt-4">
			<div className="d-flex align-items-center bg-white py-2 px-3 rounded">
				<span>Klienti nás nejčastěji hodnotí 4,9</span>
				<Star width={15} height={15} className={"ms-1"} />
			</div>
		</div>
	);
}
