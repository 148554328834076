import React from "react";
import { Carousel } from "react-bootstrap";
import "./FactsCarousel.scss";
import { shuffle } from "@/Utils/Array.js";

const items = [
	"<p style='font-weight: 600'>V roce 2023 státu propadl majetek v hodnotě více jak 440 mil. Kč.</p> Nečekejte a vytvořte si závěť i vy.",
	"<p style='font-weight: 600'>Česko je pozadu.</p> Se závětí umírá jen 15 % lidí. Neriskujte a přidejte se k více jak 25,000 Čechů, kteří si v roce 2023 sepsali závěť.",
	"<p style='font-weight: 600'>Záleží vám, co bude s vaším majetkem?</p> Zajistěte své jmění a předejděte sporům v rodině.",
];

function FactsCarousel({ className }) {
	return (
		<Carousel
			controls={false}
			indicators={false}
			interval={7000}
			className={className}
		>
			{shuffle(items).map((item, i) => (
				<Carousel.Item key={i}>
					<div
						className="d-block w-100 text-center fs-5 text-primary p-2"
						dangerouslySetInnerHTML={{ __html: item }}
					></div>
				</Carousel.Item>
			))}
		</Carousel>
	);
}

export default React.memo(FactsCarousel);
